



import { Component, Vue } from 'vue-property-decorator';
import store from '../../../store';
import VueRouter, { Route } from 'vue-router';
import axios, {AxiosResponse, AxiosError} from 'axios';
import { GetAccountResponse, ErrorResponse } from '../../../../types/typescript-axios/api';
import StepNavi from '../../../components/contents/stepNavi/StepNavi.vue';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
]);

@Component({
  components: {
    StepNavi,
  },
})
export default class AccountCancel extends Vue {
  private nameSei: string = '';
  private nameMei: string = '';
  private mail: string = '';
  private status: number = 200;
  private stepNavi: StepNaviData[] = [
    {
      title: '退会確認',
    },
    {
      title: '退会完了',
    },
  ];

  public async onCancel() {
    await store.dispatch('account/cancelTheMember', {userid: this.$store.getters['auth/isUserId']})
    .then((response: any) => {
      this.$router.push('complete/');
    }, (error: any) => {
      store.commit('window/setNotFound', 403);
      this.$router.push('/account/cancel/error/');
    });
  }
  public async pageBack() {
    window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
  }
  public async beforeRouteEnter(to: Route, from: Route, next: any) {
    await axios.get('/wp-json/moc/account')
    .then((response: AxiosResponse<GetAccountResponse>) => {
      next((vm: {
        nameSei: string,
        nameMei: string,
        mail: string,
        status: number,
      }) => {
        vm.status = response.status;
        vm.nameSei = response.data.sei;
        vm.nameMei = response.data.mei;
        vm.mail = response.data.email;
      });
    })
    .catch( async (e: AxiosError<ErrorResponse>) => {
      const $e: any = e;
      await store.dispatch('auth/logoutAuth');
      next({ path: '/login/', query: { redirect: to.fullPath }, replace: true});
    });
  }

  public async beforeRouteUpdate(to: Route, from: Route, next: any) {
    await axios.get('/wp-json/moc/account')
    .then((response: AxiosResponse<GetAccountResponse>) => {
      this.status = response.status;
      this.nameSei = response.data.sei;
      this.nameMei = response.data.mei;
      this.mail = response.data.email;
      next();
    })
    .catch( (e: AxiosError<ErrorResponse>) => {
      const $e: any = e;
      let status = 404;
      if (typeof $e !== 'undefined') {
        status = $e.response.status;
      }
      this.status = status;
      next();
    });
  }
}
