




import { Component, Vue, Emit } from 'vue-property-decorator';
import Pagenation from '../../components/contents/pangenation/Pangenation.vue';
import VueRouter from 'vue-router';
import store from '../../store';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
]);

@Component
export default class AccountCancel extends Vue {

}
